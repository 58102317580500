<template>
  <component
    :is="linkObject?.to ? NuxtLink : 'div'"
    :data-block-id="id"
    :data-block-type="__typename"
    v-bind="linkObject?.to ? linkObject : { class: 'contents' }"
  >
    <ImageWrapper
      :image="imageObject"
      :config="imageConfig"
      :classes="{
        container: [containerClassList, maxWidthClassList],
        image: [imageClassList, { 'cursor-pointer': allowZoom }],
      }"
      @click="linkObject?.to ? '' : openModal()"
    />
    <div
      v-if="caption"
      class="text-primary-dark-2 dark:text-primary-light-2 mt-2 break-words text-center text-sm"
      :class="maxWidthClassList"
      v-text="caption"
    />

    <LazyImageModal
      v-if="allowZoom && isImageModalVisible"
      v-bind="{ image: imageObject, description: caption }"
      @close="isImageModalVisible = false"
    />
  </component>
</template>

<script setup lang="ts">
import type { Image } from '#gql/default'

export interface CmsBlockImageProps {
  id: string
  // eslint-disable-next-line vue/prop-name-casing
  __typename: 'CmsImageBlock'
  image: Image
  alt?: string
  linkUrl?: string
  linkTarget?: string
  ratio: CmsImageBlockRatio
  loading: ImageLoading
  preload: boolean
  size: CmsImageBlockSize
  alignment?: CmsImageBlockAlignment
  renderAboveTheFold: boolean
  clip?: CmsImageBlockClip
  caption?: string
  allowZoom: boolean
}

const props = defineProps<CmsBlockImageProps>()

const NuxtLink = resolveComponent('NuxtLink')
const config = useAppConfig()
const isImageModalVisible = ref(false)

const imageObject = computed(() => getImage(props, 'image', props.alt))
const linkObject = computed(() => {
  if (props.linkUrl) {
    return {
      to: props.linkUrl,
      title: props.alt,
      target: props.linkTarget ?? '_self',
    }
  }
})

const width = computed(() => ({
  thumbnail: 150,
  sm: 240,
  md: 340,
  lg: 768,
  boxed: imageObject.value.width,
  original: imageObject.value.width,
}))

const imageConfig = computed(() => {
  return {
    ...config.images.cms.imageBlock,
    fit:
      props.ratio === CMS_IMAGE_BLOCK_RATIO.ORIGINAL
        ? IMAGE_FIT.CROP
        : IMAGE_FIT.STRETCH,
    height: undefined,
    loading: props.loading,
    preload: props.preload,
    width: width.value[props.size],
  }
})

function openModal() {
  isImageModalVisible.value = true
}

const { maxWidthClassList, containerClassList, imageClassList } =
  useImageClasses({
    size: props.size,
    ratio: props.ratio,
    alignment: props.alignment,
    clip: props.clip,
  })

defineOptions({
  name: 'CmsBlockImage',
})
</script>
