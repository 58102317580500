<template>
  <div :data-block-id="id" :data-block-type="__typename">
    <component :is="viewComponent" v-bind="viewProps" />
  </div>
</template>

<script lang="ts" setup>
import type { GalleryHorizontalProps } from '@base/components/gallery/horizontal.vue'
import type { GalleryVerticalProps } from '@base/components/gallery/vertical.vue'
import type { GalleryCarouselProps } from '@base/components/gallery/carousel.vue'
import type { CmsGallery } from '#gql/default'

type GalleryBlockTypes =
  | 'CmsGalleryVerticalBlock'
  | 'CmsGalleryHorizontalBlock'
  | 'CmsGalleryCarouselBlock'

type ViewProps =
  | GalleryHorizontalProps
  | GalleryVerticalProps
  | GalleryCarouselProps

export type CmsBlockGalleryProps = {
  id: string
  // eslint-disable-next-line vue/prop-name-casing
  __typename: GalleryBlockTypes
  renderAboveTheFold: boolean
  gallery: CmsGallery
  autoplay?: boolean
  infinite?: boolean
  slideDuration?: number
  hideDescription: boolean
  hideLink: boolean
  hideTitle: boolean
  galleryLayout?: string
  allowZoom?: boolean
  imageSize?: string
  imageFormat?: string
  pattern?: string
}

const props = defineProps<CmsBlockGalleryProps>()

const { mapGallerySlides } = useGallery()

const { viewComponent, viewProps } = useGetViewComponentConfig(props.__typename)

function useGetViewComponentConfig(__typename: GalleryBlockTypes) {
  let viewComponent
  let viewProps: Partial<ViewProps> = {
    renderAboveTheFold: props.renderAboveTheFold,
    hideDescription: props.hideDescription,
    hideLink: props.hideLink,
    hideTitle: props.hideTitle,
  }

  switch (__typename) {
    case 'CmsGalleryCarouselBlock':
      viewComponent = resolveComponent('GalleryCarousel')
      viewProps = {
        slides: mapGallerySlides(props.gallery.slides),
        autoplay: props.autoplay,
        infinite: props.infinite,
        slideDuration: props.slideDuration,
        ...viewProps,
      } as GalleryCarouselProps
      break
    case 'CmsGalleryVerticalBlock':
      viewComponent = resolveComponent('GalleryVertical')
      viewProps = {
        slides: mapGallerySlides(props.gallery.slides),
        imageSize: props.imageSize,
        imageFormat: props.imageFormat,
        pattern: props.pattern,
        allowZoom: props.allowZoom,
        ...viewProps,
      } as GalleryVerticalProps
      break
    case 'CmsGalleryHorizontalBlock':
      viewComponent = resolveComponent('GalleryHorizontal')
      viewProps = {
        slides: mapGallerySlides(props.gallery.slides),
        imageSize: props.imageSize,
        imageFormat: props.imageFormat,
        ...viewProps,
      } as GalleryHorizontalProps
      break
  }

  return {
    viewComponent,
    viewProps,
  }
}

defineOptions({
  name: 'CmsBlockGallery',
})
</script>
