<template>
  <PageContainer
    :data-block-id="id"
    :data-block-type="__typename"
    :default-horizontal-spacing="false"
    :default-vertical-spacing
    :full-width
    :height
    class="relative"
  >
    <PageContainerBackground
      v-if="!!backgroundImage?.url"
      :background-gradient-top
      :background-gradient-bottom
      :background-image-modifiers
      :background-image
      :background-image-position
      :preload="blockPosition === 0"
    />

    <PageGrid
      :default-horizontal-spacing
      :vertical-align
      :gap
      :height
      :layout
      :full-width
      :class="{
        container: fullWidth,
        'container-spacing-horizontal': defaultHorizontalSpacing,
      }"
    >
      <template #default="{ colSpacing }">
        <PageGridColumn
          v-for="(column, index) in columns"
          :key="index"
          :horizontal-align
          :class="colSpacing"
          :style="'z-index: ' + (columns.length - index)"
        >
          <component
            :is="dynamicComponent[component.__typename]"
            v-for="component in column"
            :key="component.id"
            :render-above-the-fold="index === 0 && blockPosition === 0"
            v-bind="component"
          />
        </PageGridColumn>
      </template>
    </PageGrid>
  </PageContainer>
</template>

<script setup lang="ts">
import type {
  CmsCinemasCatalogBlock,
  CmsProgramBlock,
  CmsMoviesCatalogBlock,
  CmsMovieListBlock,
  CmsTableBlock,
  CmsImageBlock,
  CmsHtmlBlock,
  CmsVideoBlock,
  CmsLegacyAccountWidgetBlock,
  CmsLegacyShopWidgetBlock,
  CmsGalleryCarouselBlock,
  CmsGalleryHorizontalBlock,
  CmsGalleryVerticalBlock,
  CmsNewsletterWidgetBlock,
  CmsEventimNewsletterWidgetBlock,
  CmsGoogleMapsBlock,
  CmsDividerBlock,
  CmsAdSlotBlock,
  CmsFormBlock,
  Image,
  ImageModifier,
} from '#gql/default'

type CmsBlock =
  | (CmsNewsletterWidgetBlock & { __typename: 'CmsNewsletterWidgetBlock' })
  | (CmsCinemasCatalogBlock & { __typename: 'CmsCinemasCatalogBlock' })
  | (CmsMoviesCatalogBlock & { __typename: 'CmsMoviesCatalogBlock' })
  | (CmsMovieListBlock & { __typename: 'CmsMovieListBlock' })
  | (CmsImageBlock & { __typename: 'CmsImageBlock' })
  | (CmsHtmlBlock & { __typename: 'CmsHtmlBlock' })
  | (CmsTableBlock & { __typename: 'CmsTableBlock' })
  | (CmsVideoBlock & { __typename: 'CmsVideoBlock' })
  | (CmsGalleryCarouselBlock & { __typename: 'CmsGalleryCarouselBlock' })
  | (CmsGalleryHorizontalBlock & { __typename: 'CmsGalleryHorizontalBlock' })
  | (CmsGalleryVerticalBlock & { __typename: 'CmsGalleryVerticalBlock' })
  | (CmsLegacyShopWidgetBlock & { __typename: 'CmsLegacyShopWidgetBlock' })
  | (CmsProgramBlock & { __typename: 'CmsProgramBlock' })
  | (CmsAdSlotBlock & { __typename: 'CmsAdSlotBlock' })
  | (CmsDividerBlock & { __typename: 'CmsDividerBlock' })
  | (CmsGoogleMapsBlock & { __typename: 'CmsGoogleMapsBlock' })
  | (CmsEventimNewsletterWidgetBlock & {
      __typename: 'CmsEventimNewsletterWidgetBlock'
    })
  | (CmsLegacyAccountWidgetBlock & {
      __typename: 'CmsLegacyAccountWidgetBlock'
    })
  | (CmsFormBlock & { __typename: 'CmsFormBlock' })

export interface CmsBlockLayoutProps {
  id: string
  // eslint-disable-next-line vue/prop-name-casing
  __typename: 'CmsLayoutBlock'
  height: PageContainerHeight
  fullWidth?: boolean
  blockPosition: number
  defaultHorizontalSpacing?: boolean
  defaultVerticalSpacing?: boolean
  verticalAlign?: PageGridVerticalAlign
  horizontalAlign?: PageGridColumnHorizontalAlign
  backgroundImage?: Image
  backgroundImageModifiers: ImageModifier[]
  backgroundImagePosition?: string
  backgroundGradientTop?: boolean
  backgroundGradientBottom?: boolean
  gap?: {
    default?: PageGridGapSize
    sm?: PageGridGapSize
    md?: PageGridGapSize
    lg?: PageGridGapSize
  }
  layout?: {
    sm?: PageGridLayout
    md?: PageGridLayout
    lg?: PageGridLayout
  }
  columns: CmsBlock[][]
}

const dynamicComponent = {
  CmsGalleryCarouselBlock: resolveComponent('CmsBlockGallery'),
  CmsGalleryHorizontalBlock: resolveComponent('CmsBlockGallery'),
  CmsGalleryVerticalBlock: resolveComponent('CmsBlockGallery'),
  CmsImageBlock: resolveComponent('CmsBlockImage'),
  CmsVideoBlock: resolveComponent('CmsBlockVideo'),
  CmsHtmlBlock: resolveComponent('CmsBlockHtml'),
  CmsMovieListBlock: resolveComponent('CmsBlockMovieList'),
  CmsLegacyAccountWidgetBlock: resolveComponent('CmsBlockLegacyWidget'),
  CmsLegacyShopWidgetBlock: resolveComponent('CmsBlockLegacyWidget'),
  CmsMoviesCatalogBlock: resolveComponent('CmsBlockMovies'),
  CmsCinemasCatalogBlock: resolveComponent('CmsBlockCinemas'),
  CmsNewsletterWidgetBlock: resolveComponent('CmsBlockNewsletter'),
  CmsEventimNewsletterWidgetBlock: resolveComponent(
    'LazyCmsBlockEventimNewsletter'
  ),
  CmsProgramBlock: resolveComponent('CmsBlockProgram'),
  CmsAdSlotBlock: resolveComponent('LazyCmsBlockAdSlot'),
  CmsFormBlock: resolveComponent('CmsBlockForm'),
  CmsDividerBlock: resolveComponent('CmsBlockDivider'),
  CmsGoogleMapsBlock: resolveComponent('CmsBlockGoogleMaps'),
  CmsTableBlock: resolveComponent('CmsBlockTable'),
}

withDefaults(defineProps<CmsBlockLayoutProps>(), {
  height: PAGE_CONTAINER_HEIGHT.FLEXIBLE,
  fullWidth: true,
  defaultHorizontalSpacing: true,
  defaultVerticalSpacing: true,
  verticalAlign: undefined,
  horizontalAlign: undefined,
  backgroundImagePosition: 'center',
  backgroundGradientTop: false,
  backgroundGradientBottom: false,
  backgroundImageModifiers: undefined,
  backgroundImage: undefined,
  gap: () => ({
    default: PAGE_GRID_GAP_SIZE.SM,
    sm: PAGE_GRID_GAP_SIZE.SM,
    md: PAGE_GRID_GAP_SIZE.SM,
    lg: PAGE_GRID_GAP_SIZE.SM,
  }),
  layout: () => ({
    sm: PAGE_GRID_LAYOUT.COLS_1,
    md: PAGE_GRID_LAYOUT.COLS_1,
    lg: PAGE_GRID_LAYOUT.COLS_1,
  }),
})

defineOptions({
  name: 'CmsBlockLayout',
})
</script>
