<template>
  <slot :city="city" :pending="pending" />
</template>

<script lang="ts" setup>
interface Props {
  citySlug: string
  throwError?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  throwError: false,
})

const COMPONENT_NAME = 'CityFetch'
const { t } = useI18n()

const { data, pending, execute } = await useAsyncData(
  getGqlFetchKey(COMPONENT_NAME, props.citySlug),
  () =>
    GqlFetchCity({
      urlSlug: props.citySlug,
    }),
  { immediate: false }
)

await execute()

if (!data.value?.city?.id && props.throwError) {
  showError({
    statusCode: 404,
    statusMessage: t('errors.itemNotFound', { item: t('item.city') }),
  })
}

const city = computed(() => data.value?.city)

defineOptions({
  name: COMPONENT_NAME,
})
</script>

<i18n>
de:
  errors:
    itemNotFound: "{item} nicht gefunden"
  item:
    city: "Stadt"
es:
  errors:
    itemNotFound: "{item} no encontrado"
  item:
    city: "Ciudad"
</i18n>
