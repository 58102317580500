<template>
  <div :data-block-id="id" :data-block-type="__typename">
    <LegacyWidget :options="options" />
  </div>
</template>

<script lang="ts" setup>
type LegacyWidgetTypes =
  | 'CmsLegacyShopWidgetBlock'
  | 'CmsLegacyAccountWidgetBlock'

export interface CmsBlockLegacyWidgetProps {
  id: string
  // eslint-disable-next-line vue/prop-name-casing
  __typename: LegacyWidgetTypes
  renderAboveTheFold: boolean
  params: any
  shop?: string
}

const props = defineProps<CmsBlockLegacyWidgetProps>()
const runtimeConfig = useRuntimeConfig()
const cmsConfig = useCmsConfig()

const options = computed(() => {
  const { params } = props.params
  const parsedOptions = JSON.parse(params ?? '{}')

  if (props.__typename === 'CmsLegacyAccountWidgetBlock') {
    parsedOptions.cid = cmsConfig.value?.cinemas[0].cid
    parsedOptions.cinemaPath = 'user/index'
  }

  if (props.__typename === 'CmsLegacyShopWidgetBlock') {
    parsedOptions.cinemaPath = `kino/${cmsConfig.value?.cinemas[0].city.urlSlug}/${cmsConfig.value?.cinemas[0].urlSlug}/${props.shop}`
  }

  return {
    ...parsedOptions,
    baseUrl: runtimeConfig.public.WIDGET_BASE_URL,
  }
})

defineOptions({
  name: 'CmsBlockLegacyWidget',
})
</script>
