<template>
  <button
    :type="BUTTON_TYPE.BUTTON"
    :title="t('btn.playTrailer')"
    :class="cssClasses.container"
  >
    <img :src="thumbnailUrl" :class="cssClasses.image" />
    <InlineSvg :name="SVG_ICONS.PLAY_TRAILER" :class="cssClasses.icon" />
  </button>
</template>

<script lang="ts" setup>
interface Props {
  remoteVideoId: string
  classes?: {
    container?: string | object | []
    image?: string | object | []
    icon?: string | object | []
  }
}

const props = withDefaults(defineProps<Props>(), {
  classes: () => ({}),
})
const { t } = useI18n()
const thumbnailUrl = computed(
  () => `/api/youtube-image/vi_webp/${props.remoteVideoId}/mqdefault.webp`
)

const cssClasses = computed(() => {
  const { container, image, icon } = props.classes

  return {
    container:
      container ?? 'relative flex h-full w-full items-center justify-center',
    image: image ?? 'absolute inset-0 h-full w-full object-cover',
    icon: icon ?? 'z-10 h-6 w-6',
  }
})

defineOptions({
  name: 'YoutubeThumbnail',
})
</script>

<i18n>
de:
  btn:
    playTrailer: "Trailer abspielen"
es:
  btn:
    playTrailer: "Reproducir tráiler"
</i18n>
