<template>
  <PageContainer
    :full-width="appConfig.fullWidthHeroBlocks"
    :default-vertical-spacing="false"
    :default-horizontal-spacing="false"
    :height="PAGE_CONTAINER_HEIGHT.SM"
    class="relative"
  >
    <PageContainerBackground
      v-if="cinema.heroImage"
      :background-gradient-bottom="true"
      :background-image="cinema.heroImage"
      :background-image-position="cinema.heroImageAlignment"
      :background-image-modifiers="appConfig.cinemaHeroImageModifiers"
      :preload="true"
      class="opacity-60"
    />

    <div
      class="container-spacing-horizontal container absolute inset-0 z-10 grid h-full w-full grid-cols-[auto,1fr] content-center gap-4 md:gap-6"
    >
      <CinemaLogo
        class="size-20 sm:size-32 md:size-40"
        :image-object="logo"
        :image-config="logoConfig"
        :rounded="ROUNDED_TYPE.FULL"
      />

      <div class="flex flex-col justify-center space-y-1.5">
        <span
          class="text-2xl font-bold leading-none sm:text-3xl md:text-5xl"
          v-text="cinema.name"
        />
        <span class="text-sm sm:text-lg" v-text="cinema.city.name" />
      </div>
    </div>
  </PageContainer>
</template>

<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
}

const props = defineProps<Props>()
const appConfig = useAppConfig()

const logo = getImage(props.cinema, 'thumbnailImage', props.cinema.name)
const logoConfig = computed(() => ({
  fit: IMAGE_FIT.CROP,
  width: 150,
  height: 150,
  loading: IMAGE_LOADING.EAGER,
}))

defineOptions({
  name: 'CinemaHead',
})
</script>
