<template>
  <Suspense>
    <NuxtPage v-if="!pending && movie" :cinema :movie :url-slug />
  </Suspense>
</template>

<script setup lang="ts">
import type { Cinema, ProgramGroupByMovie } from '#gql/default'

interface Props {
  cinema: Cinema
}

const { cinema } = defineProps<Props>()

const route = useRoute()

const { results, pending, error } = await useProgram({
  fetchOperation: 'FetchProgramForCinemaShowGroup',
  fetchFallbackResults: false,
  preselected: {
    cinemaIds: [cinema.id],
    showGroups: [route.params.showGroupSlug as string],
  },
  first: 1,
})

const groups = computed(() => results.value as unknown as ProgramGroupByMovie[])
const movie = computed(() => groups.value?.[0]?.movie)
const urlSlug = computed(() => groups.value?.[0]?.urlSlug)

if (error.value || (!movie.value && !pending.value)) {
  const { redirectWithError } = useRedirectError()

  await redirectWithError({
    message: error.value ?? REDIRECT_ERROR_STRINGS.NO_MOVIE,
    redirect: {
      name: ROUTE_NAMES.CINEMA_PROGRAM,
      params: {
        citySlug: route.params.citySlug as string,
        cinemaSlug: route.params.cinemaSlug as string,
      },
    },
    cinemaId: cinema.id,
  })
}

defineI18nRoute({
  paths: {
    en: '/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]',
    de: '/kino/[citySlug]/[cinemaSlug]/film/[showGroupSlug]',
  },
})

defineOptions({
  name: 'PageCinemaCitySlugCinemaSlugMovieShowGroupSlug',
})
</script>
