import type { CmsPage } from '#gql/default'

export default async function useCmsPageFetch(throwError = false) {
  const page = ref()
  const cmsConfig = useCmsConfig()
  const { design, applyDesign } = useDesign()
  const { currentUrl } = useCurrentUrl()
  const { t, mergeLocaleMessage } = useI18n()

  mergeLocaleMessage('de-DE', {
    pageNotFound: 'Seite nicht gefunden.',
  })

  mergeLocaleMessage('es-ES', {
    pageNotFound: 'Página no encontrado.',
  })

  const { data, execute, pending } = await useAsyncData(
    getGqlFetchKey('CmsPageFetch', currentUrl.value),
    () => GqlFetchCmsPage({ url: currentUrl.value }),
    { immediate: false }
  )

  const components = computed(() => data.value?.cmsPage?.components ?? [])

  async function fetchCmsPage() {
    await execute()

    design.value = data.value?.cmsPage?.design
      ? data.value?.cmsPage?.design
      : cmsConfig.value?.defaultDesign

    applyDesign()

    if (!data.value?.cmsPage?.id && throwError) {
      const errorMessage = t('pageNotFound')

      showError({
        statusCode: 404,
        statusMessage: errorMessage,
      })
    }

    page.value = (data.value?.cmsPage as CmsPage) ?? undefined
  }

  await fetchCmsPage()

  return {
    page,
    components,
    pending,
  }
}
