<template>
  <div
    :class="containerClassList"
    :data-block-id="id"
    data-block-type="CmsDividerBlock"
  >
    <div class="w-full" :class="separatorClassList" />
  </div>
</template>

<script lang="ts" setup>
export interface CmsBlockDividerProps {
  id: string
  // eslint-disable-next-line vue/prop-name-casing
  __typename: 'CmsDividerBlock'
  layout: string
  verticalSpacing: string
  horizontalSpacing: string
  separatorStyle: string
}

const { layout, verticalSpacing, horizontalSpacing, separatorStyle } =
  defineProps<CmsBlockDividerProps>()

const containerClassList = computed(() => {
  const classes = []

  if (verticalSpacing === DIVIDER_VERTICAL_SPACING.SM) {
    classes.push('py-4')
  } else if (verticalSpacing === DIVIDER_VERTICAL_SPACING.MD) {
    classes.push('py-8')
  } else if (verticalSpacing === DIVIDER_VERTICAL_SPACING.LG) {
    classes.push('py-12')
  } else if (verticalSpacing === DIVIDER_VERTICAL_SPACING.XL) {
    classes.push('py-16')
  }

  if (horizontalSpacing === DIVIDER_HORIZONTAL_SPACING.SM) {
    classes.push('px-4')
  } else if (horizontalSpacing === DIVIDER_HORIZONTAL_SPACING.MD) {
    classes.push('px-8')
  } else if (horizontalSpacing === DIVIDER_HORIZONTAL_SPACING.LG) {
    classes.push('px-12')
  } else if (horizontalSpacing === DIVIDER_HORIZONTAL_SPACING.XL) {
    classes.push('px-16')
  }

  return classes
})

const separatorClassList = computed(() => {
  const classes = []

  if (separatorStyle === DIVIDER_SEPARATOR_STYLE.NONE) {
    classes.push('hidden')
  } else if (separatorStyle === DIVIDER_SEPARATOR_STYLE.XS) {
    classes.push('h-px')
  } else if (separatorStyle === DIVIDER_SEPARATOR_STYLE.SM) {
    classes.push('h-1')
  } else if (separatorStyle === DIVIDER_SEPARATOR_STYLE.MD) {
    classes.push('h-2.5')
  } else if (separatorStyle === DIVIDER_SEPARATOR_STYLE.LG) {
    classes.push('h-4')
  }

  if (layout === DIVIDER_LAYOUT.PRIMARY) {
    classes.push('bg-primary')
  } else if (layout === DIVIDER_LAYOUT.SECONDARY) {
    classes.push('bg-secondary')
  } else if (layout === DIVIDER_LAYOUT.NEUTRAL) {
    classes.push('bg-neutral')
  }

  return classes
})

defineOptions({
  name: 'CmsBlockDivider',
})
</script>
