export default function useImageClasses({
  size,
  ratio,
  alignment,
  clip,
}: {
  size: CmsImageBlockSize
  ratio: CmsImageBlockRatio
  alignment?: CmsImageBlockAlignment
  clip?: CmsImageBlockClip
}) {
  const maxWidthClassList = computed(() => ({
    'max-w-[150px]': size === CMS_IMAGE_BLOCK_SIZE.THUMBNAIL,
    'max-w-[240px]': size === CMS_IMAGE_BLOCK_SIZE.SM,
    'max-w-[340px]': size === CMS_IMAGE_BLOCK_SIZE.MD,
    'max-w-[768px]': size === CMS_IMAGE_BLOCK_SIZE.LG,
    'w-full': size === CMS_IMAGE_BLOCK_SIZE.BOXED,
    'max-w-fit': ratio === CMS_IMAGE_BLOCK_RATIO.ORIGINAL,
  }))

  const containerClassList = computed(() => [
    'flex flex-col relative',
    {
      'aspect-auto': ratio === CMS_IMAGE_BLOCK_RATIO.ORIGINAL,
      'aspect-[1/1]': ratio === CMS_IMAGE_BLOCK_RATIO.RATIO_1_1,
      'aspect-[3/4]': ratio === CMS_IMAGE_BLOCK_RATIO.RATIO_3_4,
      'aspect-[4/1]': ratio === CMS_IMAGE_BLOCK_RATIO.RATIO_4_1,
      'aspect-[16/9]': ratio === CMS_IMAGE_BLOCK_RATIO.RATIO_16_9,
      'mr-auto': alignment === CMS_IMAGE_BLOCK_ALIGNMENT.LEFT,
      'ml-auto': alignment === CMS_IMAGE_BLOCK_ALIGNMENT.RIGHT,
      'mx-auto': alignment === CMS_IMAGE_BLOCK_ALIGNMENT.CENTER,
    },
  ])

  const imageClassList = computed(() => [
    'w-full object-cover',
    {
      'h-full absolute top-0': ratio !== CMS_IMAGE_BLOCK_RATIO.ORIGINAL,
      'object-top': clip === IMAGE_CLIP.TOP,
      'object-bottom': clip === IMAGE_CLIP.BOTTOM,
      'object-center': clip === IMAGE_CLIP.CENTER,
      'object-left': clip === IMAGE_CLIP.LEFT,
      'object-left-top': clip === IMAGE_CLIP.LEFT_TOP,
      'object-left-bottom': clip === IMAGE_CLIP.LEFT_BOTTOM,
      'object-right': clip === IMAGE_CLIP.RIGHT,
      'object-right-top': clip === IMAGE_CLIP.RIGHT_TOP,
      'object-right-bottom': clip === IMAGE_CLIP.RIGHT_BOTTOM,
    },
  ])

  return {
    maxWidthClassList,
    containerClassList,
    imageClassList,
  }
}
