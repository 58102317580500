<template>
  <PageContainer
    as="article"
    :default-horizontal-spacing="false"
    :default-vertical-spacing="false"
    :full-width="true"
    :default-theme="false"
    :data-cms-page="page.id"
    class="flex grow flex-col"
  >
    <CmsBlockLayout
      v-for="(component, index) in components"
      :key="component.id"
      :block-position="index"
      v-bind="component"
    />
  </PageContainer>
</template>

<script lang="ts" setup>
import type { CmsPage, CmsLayoutBlock } from '#gql/default'

interface Props {
  page: CmsPage
  components: CmsLayoutBlock[]
}

const { page } = defineProps<Props>()
const { $gtm } = useNuxtApp()

usePageMetas({
  title: page.title,
  ...page.meta,
})

onMounted(() => {
  $gtm.trackPageView()
})

defineOptions({
  name: 'CmsPage',
})
</script>
