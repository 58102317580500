<template>
  <CatalogProgram
    :filters="[
      URL_FILTER.DATES,
      URL_FILTER.LANGUAGE,
      URL_FILTER.TECHNOLOGY,
      URL_FILTER.EVENT,
      URL_FILTER.MISCELLANEOUS,
    ]"
    :layout="PROGRAM_LAYOUT.GROUPED_BY_MOVIE"
    :items-per-page="10"
    :cinema-ids="[cinema.id]"
    :show-groups="[urlSlug]"
    :fetch-fallback-results="true"
    :link-params="[]"
    :preserve-filters="false"
    :booking-modal="false"
    :render-above-the-fold="false"
  />
</template>

<script setup lang="ts">
import type { Cinema, Movie } from '#gql/default'

interface Props {
  cinema: Cinema
  movie: Movie
  urlSlug: string
}

const { movie, cinema, urlSlug } = defineProps<Props>()

const { t } = useI18n()

usePageMetas({
  title: `${cinema.name} | ${t('tickets')}`,
  description: t('description', {
    cinemaName: cinema.name,
    movieTitle: movie.title,
  }),
  ...movie.meta,
})

defineI18nRoute({
  paths: {
    en: '/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]',
    de: '/kino/[citySlug]/[cinemaSlug]/film/[showGroupSlug]',
  },
})

defineOptions({
  name: 'PageCinemaCitySlugCinemaSlugMovieShowGroupSlugIndex',
})
</script>

<i18n>
de:
  tickets: "Kinotickets"
  description: "Jetzt bei kinoheld Kinotickets für das Kino {cinemaName} und den Film {movieTitle} schnell und bequem online kaufen."
es:
  tickets: "Cartelera y horarios"
  description: "Compra tus entradas de cine para el cine {cinemaName} y la película {movieTitle} de forma rápida y cómoda en entradas."
</i18n>
