export const CMS_CONFIG_SCOPE = {
  CINEMAS: 'Cinemas',
  PORTAL: 'Portal',
}

export const CMS_HTML_BLOCK_FORMAT_SIZE = {
  SM: 'sm',
  BASE: 'base',
  LG: 'lg',
  XL: 'xl',
  XXL: '2xl',
} as const

export const CMS_IMAGE_BLOCK_RATIO = {
  ORIGINAL: 'original',
  RATIO_1_1: '1:1',
  RATIO_3_4: '3:4',
  RATIO_16_9: '16:9',
  RATIO_4_1: '4:1',
} as const

export const CMS_IMAGE_BLOCK_SIZE = {
  THUMBNAIL: 'thumbnail',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  BOXED: 'boxed',
  ORIGINAL: 'original',
} as const

export const CMS_IMAGE_BLOCK_ALIGNMENT = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
} as const
