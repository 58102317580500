export const TABLE_LAYOUT = {
  SIMPLE: 'simple',
  VERTICAL_LINES: 'vertical-lines',
  STRIPES: 'stripes',
} as const

export const TABLE_CAPTION_POSITION = {
  TOP: 'top',
  BOTTOM: 'bottom',
} as const
