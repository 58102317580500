import type { Cinema } from '#gql/default'

export default async function useCinema({
  variables,
  throwError,
}: {
  variables: { urlSlug: string; cityUrlSlug: string }
  throwError?: boolean
}) {
  const { t, mergeLocaleMessage } = useI18n()
  const { backendToken } = useBackendToken()

  mergeLocaleMessage('de-DE', {
    cinemaNotFound: 'Kino nicht gefunden.',
  })
  mergeLocaleMessage('es-ES', {
    cinemaNotFound: 'Cine no encontrado.',
  })

  if (backendToken.value) {
    useGqlHeaders({ Authorization: `Bearer ${backendToken.value}` })
  }

  const { data, status } = await useAsyncGql(
    'FetchCinemaForCinemaPage',
    variables
  )

  if (throwError && !data.value?.cinema) {
    const errorMessage = t('cinemaNotFound')

    showError({
      statusCode: 404,
      statusMessage: errorMessage,
    })
  }

  const cinema = computed(() => data.value?.cinema as Cinema | undefined)
  const pending = computed(() => status.value === 'pending')

  return {
    cinema,
    pending,
  }
}
