<template>
  <div :data-block-id="id" :data-block-type="__typename">
    <table class="w-full table-fixed">
      <caption
        v-if="caption"
        class="text-neutral-dark-1 dark:text-neutral-light-1 py-4 text-sm italic"
        :class="{
          'caption-bottom': captionPosition === TABLE_CAPTION_POSITION.BOTTOM,
          'caption-top': captionPosition === TABLE_CAPTION_POSITION.TOP,
        }"
        v-text="caption"
      />
      <tbody>
        <tr
          v-for="row in rows"
          :key="row.id"
          :class="{
            'odd:bg-neutral-light-2': layout === TABLE_LAYOUT.STRIPES,
          }"
        >
          <td
            v-for="(column, index) in row"
            :key="column.id"
            :colspan="
              index === row.length - 1 && row.length < maxCols
                ? maxCols - row.length + 1
                : undefined
            "
            class="dark:border-neutral-dark-1 border-neutral-light-1 border-b border-t align-top"
            :class="{
              'py-4 pl-2 pr-2 first:pl-0 last:pr-0':
                layout === TABLE_LAYOUT.SIMPLE,
              'p-2 ': layout !== TABLE_LAYOUT.SIMPLE,
              'border-r first:border-l': layout === TABLE_LAYOUT.VERTICAL_LINES,
            }"
          >
            <CmsBlockHtml
              v-for="block in column"
              :key="block.id"
              v-bind="block"
              :render-above-the-fold="false"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
export interface CmsBlockTableProps {
  id: string
  // eslint-disable-next-line vue/prop-name-casing
  __typename: 'CmsTableBlock'
  rows: any
  caption: string
  captionPosition?: TableCaptionPosition
  layout: TableLayout
  renderAboveTheFold?: boolean
}

const { rows } = defineProps<CmsBlockTableProps>()

const maxCols = computed(() =>
  rows.reduce(
    (max: number, row: string | any[]) => Math.max(max, row.length),
    0
  )
)

defineOptions({
  name: 'CmsBlockTable',
})
</script>
