<template>
  <div :data-block-id="id" :data-block-type="__typename">
    <ConsentManagerRenderHtml :html="html">
      <template #default="{ sanitizedHtml }">
        <div v-accordion :class="containerClassList" v-html="sanitizedHtml" />
      </template>
    </ConsentManagerRenderHtml>
  </div>
</template>

<script lang="ts" setup>
export interface CmsBlockHtmlProps {
  id: string
  // eslint-disable-next-line vue/prop-name-casing
  __typename: 'CmsHtmlBlock'
  html: string
  formatSize?: CmsHtmlBlockFormatSize
  formatted?: boolean
  renderAboveTheFold: boolean
}

const props = defineProps<CmsBlockHtmlProps>()

const containerClassList = computed(() => {
  if (!props.formatted) {
    return {}
  }

  return {
    'prose dark:prose-invert prose-h1:mt-8 sm:prose-h1:mt-12 prose-h1:heading-xl prose-h2:heading-lg prose-h3:heading max-w-none':
      true,
    'prose-sm': props.formatSize === CMS_HTML_BLOCK_FORMAT_SIZE.SM,
    'prose-lg': props.formatSize === CMS_HTML_BLOCK_FORMAT_SIZE.LG,
    'prose-xl': props.formatSize === CMS_HTML_BLOCK_FORMAT_SIZE.XL,
    'prose-2xl': props.formatSize === CMS_HTML_BLOCK_FORMAT_SIZE.XXL,
  }
})

const vAccordion = {
  mounted: (el: HTMLElement) => {
    const accordions = el.querySelectorAll('.accordion-header')

    accordions.forEach((accordion) => {
      accordion.addEventListener('click', () => {
        const panel = accordion.nextElementSibling

        if (panel && panel.classList.contains('accordion-content')) {
          accordion.toggleAttribute('data-accordion-open')
          panel.toggleAttribute('data-accordion-open')
        }
      })
    })
  },
}

defineOptions({
  name: 'CmsBlockHtml',
})
</script>
