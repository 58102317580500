<template>
  <ConsentManagerWarning
    v-if="showWarning"
    :can-be-activated="!!canBeActivated"
    :foreign-domains="foreignDomains"
  />
  <slot :sanitized-html="sanitizedHtml" />
</template>

<script lang="ts" setup>
interface Props {
  html: string
}

const props = defineProps<Props>()
defineOptions({
  name: 'ConsentManagerRenderHtml',
})

const sanitizedHtml = computed(() => {
  const regex = /src\s*=\s*"([^"]+)"/g
  return !showWarning.value
    ? props.html
    : props.html.replace(regex, 'data-src="$1"')
})

const { allowedDomains, allDomains } = useConsentManager()
const { currentHost } = useCurrentUrl()

const foreignDomains = computed(() => {
  // find src="" attributes in html
  const srcRegex = /src="([^"]*)"/g
  let matches
  const output = []
  while ((matches = srcRegex.exec(props.html))) {
    try {
      const url = new URL(matches[1], currentHost.value)
      if (url.protocol !== ':') {
        output.push(url.hostname)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return output
})

const showWarning = computed(
  () =>
    foreignDomains.value.length &&
    foreignDomains.value.some(
      (domain) => !allowedDomains.value.includes(domain)
    )
)
const canBeActivated = computed(
  () =>
    foreignDomains.value.length &&
    foreignDomains.value.every((domain) => allDomains.value?.includes(domain))
)
</script>

<i18n>
de:
  message:
    warning: "Diese Seite möchte Daten von {domain} übertragen"
    blocked: "Datenübertragung von {domain} wurde unterbunden"
  btn:
    allow: 'zulassen'
    settings: 'Einstellungen'
es:
  message:
    warning: 'Esta página quiere transferir datos de {domain}'
    blocked: 'La transferencia de datos de {domain} ha sido bloqueada'
  btn:
    allow: 'permitir'
    settings: 'Configuración'
</i18n>
