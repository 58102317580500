<template>
  <NuxtPage v-if="!pending && show" :cinema :show />
</template>

<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
}

const { cinema } = defineProps<Props>()

const route = useRoute()

const { show, pending, error } = await useShow({
  cinemaId: cinema.id,
  urlSlug: route.params.showSlug as string,
})

if (error.value || (!show.value && !pending.value)) {
  const { redirectWithError } = useRedirectError()

  await redirectWithError({
    message: error.value || REDIRECT_ERROR_STRINGS.NO_SHOW,
    redirect: {
      name: ROUTE_NAMES.CINEMA_PROGRAM,
      params: {
        citySlug: cinema.city.urlSlug,
        cinemaSlug: cinema.urlSlug,
      },
    },
    cinemaId: cinema.id,
  })
}

defineI18nRoute({
  paths: {
    en: '/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]',
    de: '/kino/[citySlug]/[cinemaSlug]/vorstellung/[showSlug]',
    es: '/cine/[citySlug]/[cinemaSlug]/evento/[showSlug]',
  },
})

defineOptions({
  name: 'PageCinemaCitySlugCinemaSlugShowShowSlug',
})
</script>
