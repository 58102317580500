<template>
  <ConsentManagerWarning
    v-if="!hasConsent"
    :can-be-activated="true"
    :foreign-domains="['static.newsletter2go.com']"
  />
  <div id="n2g-container"></div>
</template>

<script lang="ts" setup>
declare global {
  interface Window {
    n2g: (...args: any[]) => void
    Newsletter2GoTrackingObject: string
  }
}

interface CmsBlockNewsletter2GoProps {
  id: string
  // eslint-disable-next-line vue/prop-name-casing
  __typename: 'CmsBlockNewsletter'
  renderAboveTheFold: boolean
  newsletterId: string
}

const props = defineProps<CmsBlockNewsletter2GoProps>()
const { getConsentForDomain } = useConsentManager()
const hasConsent = computed(() =>
  getConsentForDomain('static.newsletter2go.com')
)

function renderWidget() {
  useHead({
    script: [
      {
        children: `
      !function(e,t,n,c,r,a,i){e.Newsletter2GoTrackingObject=r,e[r]=e[r]||function(){(e[r].q=e[r].q||[]).push(arguments)},e[r].l=1*new Date,a=t.createElement(n),i=t.getElementsByTagName(n)[0],a.async=1,a.src=c,i.parentNode.insertBefore(a,i)}(window,document,"script","https://static.newsletter2go.com/utils.js","n2g");

      var config = {
        container: {
          type: 'div',
          class: 'prose dark:prose-invert',
        },
        row: {
          type: 'div',
          class: 'foo',
        },
        columnLeft: {
          type: 'div',
          class: '',
          style: '',
        },
        columnRight: {
          type: 'div',
          class: '',
          style: '',
        },
        checkbox: {
          type: 'input',
          class: '',
          style: '',
        },
        separator: {
          type: 'br',
          class: '',
          style: '',
        },
        input: {
          class: 'bg-transparent border border-neutral rounded p-2',
          style: '',
        },
        button: {
          type: 'button',
          class: 'button button-primary button-base',
          style: '',
        },
        label: {
          type: 'label',
          class: '',
          style: '',
        },
        message: {
          "type": "h2",
          "class": "",
          "id": "",
          "style": "text-align: center;"
        },
      };

      n2g('create', '${props.newsletterId}');
      n2g('subscribe:createForm', config,'n2g-container');
      `,
      },
    ],
  })
}

watch(hasConsent, () => {
  if (hasConsent.value) {
    renderWidget()
  }
})

onMounted(() => {
  if (hasConsent.value) {
    renderWidget()
  }
})

defineOptions({
  name: 'CmsBlockNewsletter',
})
</script>
