import type { CmsBlockCinemasProps } from '@base/components/cms/block/cinemas.vue'
import type { CmsBlockMoviesProps } from '@base/components/cms/block/movies.vue'
import type { CmsBlockMovieListProps } from '@base/components/cms/block/movie-list.vue'

export default function useCatalogConfig(
  props: CmsBlockCinemasProps | CmsBlockMoviesProps | CmsBlockMovieListProps
) {
  const { id, __typename, showFallbackResults, ...rest } = props

  return {
    dataBlockId: id,
    dataBlockType: __typename,
    catalogProps: computed(() => ({
      ...rest,
      fetchFallbackResults: showFallbackResults,
      urlPrefix: id,
    })),
  }
}
