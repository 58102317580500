<template>
  <div class="contents">
    <CmsPage v-if="!pending && page" :page :components />

    <GenericPageCity v-else-if="!pending && !page" :city :distance="25" />
  </div>
</template>

<script lang="ts" setup>
import type { City } from '#gql/default'

interface Props {
  city: City
}

defineProps<Props>()

const { pending, page, components } = await useCmsPageFetch()

defineOptions({
  name: 'PageCinemaCitySlugIndex',
})
</script>
