<template>
  <CatalogMovies
    v-bind="catalogProps"
    :layout="catalogProps.layout as MoviesLayout"
    :data-block-id
    :data-block-type
  />
</template>

<script lang="ts" setup>
import type { LinkParam } from '#gql/default'

export interface CmsBlockMoviesProps {
  id: string
  // eslint-disable-next-line vue/prop-name-casing
  __typename: 'CmsMoviesCatalogBlock'
  layout: MoviesLayout
  enablePagination?: boolean
  renderAboveTheFold: boolean
  linkParams: LinkParam[]
  periods?: string[]
  genres?: string[]
  autoplay: boolean
  infinite: boolean
  slideDuration: number
  contentRatings?: string[]
  search?: string
  city?: string
  itemsPerPage: number
  filters: UrlFilter[]
  preserveFilters: boolean
  showFallbackResults: boolean
}

const props = defineProps<CmsBlockMoviesProps>()
const { dataBlockId, dataBlockType, catalogProps } = useCmsCatalogConfig(props)

defineOptions({
  name: 'CmsBlockMovies',
})
</script>
