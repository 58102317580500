<template>
  <CinemaBanner :cinema page="SHOW" class="mb-8" />
  <div id="kinoheld-eframe" />
</template>

<script setup lang="ts">
import type { Cinema, Show } from '#gql/default'

interface Props {
  cinema: Cinema
  show: Show
}

const { cinema, show } = defineProps<Props>()
const { t } = useI18n()

usePageMetas({
  title: `${cinema.name} | ${t('tickets')}`,
  description: t('description', {
    showName: show.name,
    cinemaName: cinema.name,
  }),
  ...show.meta,
})

useLegacyWidget({
  iframeName: 'kinoheld-widget',
  cinemaPath: `kino-${cinema.city.urlSlug}/${cinema.urlSlug}/show/${show.urlSlug}`,
})

defineI18nRoute({
  paths: {
    en: '/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]',
    de: '/kino/[citySlug]/[cinemaSlug]/vorstellung/[showSlug]',
    es: '/cine/[citySlug]/[cinemaSlug]/evento/[showSlug]',
  },
})

const { $gtm } = useNuxtApp()

onMounted(() => {
  $gtm.trackPageView()
})

defineOptions({
  name: 'PageCinemaCitySlugCinemaSlugShowShowSlugIndex',
})
</script>

<i18n>
de:
  tickets: "Kinotickets"
  description: "Jetzt bei kinoheld Tickets für den Film {showName} im Kino {cinemaName} schnell und bequem online kaufen."
es:
  tickets: "Cartelera y horarios"
  description: "Compra ahora en entradas para la película {showName} en el cine {cinemaName} de forma rápida y sencilla."
</i18n>
